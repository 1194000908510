import { useEffect, useState } from "react";
import { ModalProvider } from "@mytutor/shared-react-web-components/lib/ModalContext";
import { AppProps } from "next/app";
import { ThemeRegistry } from "../components/ThemeRegistry";
import "../styles/founders.css";
import { Snowplow } from "@mytutor/snowplow-tracking";
import { CookieEventTracking } from "@mytutor/shared-react-web-components/lib/CookieBanner";
import debounce from "debounce";
import AnalyticsConsentContext from "../context/consent";

declare global {
  interface Window {
    getCkyConsent: () => { categories: { analytics: boolean } };
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const [hasAnalyticsConsent, setHasAnalyticsConsent] = useState(false);

  function CheckConsent() {
    if (typeof window.getCkyConsent !== "function") return false;
    const cookieConsent = window.getCkyConsent();
    return cookieConsent?.categories.analytics;
  }

  useEffect(() => {
    setTimeout(() => {
      if (typeof window === "undefined") return;
      setHasAnalyticsConsent(CheckConsent());
    }, 1000);
  }, []);

  useEffect(() => {
    if (typeof document === "undefined") return;

    const handleConsentUpdate = () => {
      setHasAnalyticsConsent(CheckConsent());
    };

    const debounceConsentUpdate = debounce(handleConsentUpdate, 3000);
    document.addEventListener(
      "cookieyes_consent_update",
      debounceConsentUpdate
    );

    return () => {
      document.removeEventListener(
        "cookieyes_consent_update",
        debounceConsentUpdate
      );
    };
  }, []);

  return (
    <ModalProvider>
      {(hasAnalyticsConsent ||
        process.env.NEXT_PUBLIC_FORCE_ENABLE_SP_ANALYTICS === "true") && (
        <Snowplow
          appId={`${process.env.NEXT_PUBLIC_APP_ENVIRONMENT}-app-homepage-frontend`}
          collectionUrl={process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL ?? ""}
          isEnabledEnv={process.env.NEXT_PUBLIC_INIT_SNOWPLOW ?? "false"}
        />
      )}
      {process.env.NEXT_PUBLIC_ENABLE_COOKIEYES === "true" && (
        <CookieEventTracking />
      )}
      <AnalyticsConsentContext.Provider value={hasAnalyticsConsent}>
        <ThemeRegistry>
          <Component {...pageProps} />
        </ThemeRegistry>
      </AnalyticsConsentContext.Provider>
    </ModalProvider>
  );
}

export default MyApp;
